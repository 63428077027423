import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import axios from 'axios';

Vue.use(Vuex);
const getDefaultState = () => {
  return {
    token: "",
    refreshToken: "",
    user: null,
    mapZoom: 5,
    mapCenter: { lat: 63.0, lng: 19.0 },
    addons: {
      customers: [],
      units: [],
    },
    roundingToggleMap: false,
    navParams: {},
    sidebarOpen: false,
    pageTitle: "",
    playAlarmSound: true,
    appLocale: "",
    alarmDuration: 3,
    deviceOptions: [],
    unitOptions: [],
    gps_data: [],
    alarms: [],
    optimizedPath: [],
    imgRef: "/assets/logos/logo_menu.png",
  };
};

export default new Vuex.Store({
  strict: true,
  plugins: [
    createPersistedState({
      storage: window.localStorage,
    }),
  ],
  state: getDefaultState(),
  getters: {
    isLoggedIn: (state) => {
      return state.token;
    },
    getUser: (state) => {
      return state.user;
    },
    getMapZoom: (state) => {
      return state.mapZoom;
    },
    getMapCenter: (state) => {
      return state.mapCenter;
    },
    getAddons: (state) => {
      return state.addons;
    },
    getRoundingToggleMap: (state) => {
      return state.roundingToggleMap;
    },
    getNavParams: (state) => {
      return state.navParams;
    },
    getSidebarOpen: (state) => {
      return state.sidebarOpen;
    },
    getPageTitle: (state) => {
      return state.pageTitle;
    },
    getAlaramDuration: (state) => {
      return state.alarmDuration;
    },
    getAlarmSoundState: (state) => {
      return state.playAlarmSound;
    },
    getAppLocale: (state) => {
      return state.appLocale;
    },
    getUnitOptions: (state) => {
      return state.unitOptions;
    },
    getDeviceOptions: (state) => {
      return state.deviceOptions;
    },
    getGpsData: (state) => {
      return state.gps_data;
    },
    getAlarms: (state) => {
      return state.alarms;
    },
    getOptimizedPath: (state) => {
      return state.optimizedPath;
    },
    getImgRef: (state) => {
      return state.imgRef;
    },
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_REFRESH_TOKEN: (state, refreshToken) => {
      state.refreshToken = refreshToken;
    },
    SET_USER: (state, user) => {
      state.user = user;
    },
    SET_MAP_ZOOM: (state, mapZoom) => {
      state.mapZoom = mapZoom;
    },
    SET_MAP_CENTER: (state, mapCenter) => {
      state.mapCenter = mapCenter;
    },
    SET_ADDONS: (state, addons) => {
      state.addons = addons;
    },
    SET_ROUNDING_TOGGLE_MAP: (state, roundingToggleMap) => {
      state.roundingToggleMap = roundingToggleMap;
    },
    SET_NAV_PARAMS: (state, navParams) => {
      state.navParams = navParams;
    },
    SET_SIDEBAR_OPEN: (state, sidebarOpen) => {
      state.sidebarOpen = sidebarOpen;
    },
    SET_PAGE_TITLE: (state, pageTitle) => {
      state.pageTitle = pageTitle;
    },
    RESET: (state) => {
      Object.assign(state, getDefaultState());
    },
    SET_ALARM_SOUND_STATE: (state, isAudioEnabled) => {
      state.playAlarmSound = isAudioEnabled;
    },
    SET_ALARM_ID: (state, alarmId) => {
      state.playingAlarmId = alarmId;
    },
    SET_ALARM_DURATION: (state, duration) => {
      state.alarmDuration = duration;
    },
    SET_APP_LOCALE: (state, locale) => {
      state.appLocale = locale;
    },
    SET_UNIT_OPTIONS: (state, units) => {
      state.unitOptions = units;
    },
    SET_DEVICE_OPTIONS: (state, device) => {
      state.deviceOptions = device;
    },
    SET_ALARMS: (state, alarms) => {
      state.alarms = alarms;
    },
    SET_GPS_DATA: (state, gps_data) => {
      state.gps_data = gps_data;
    },
    SET_OPTIMIZED_PATH: (state, data) => {
      state.optimizedPath = data;
    },
    SET_IMGREF: (state, imgRef) => {
      state.imgRef = imgRef;
    },
  },

  actions: {
    login: ({ commit }, { token, userObj, addons, refreshToken, imgRef }) => {
      commit("SET_TOKEN", token);
      commit("SET_REFRESH_TOKEN", refreshToken);
      commit("SET_USER", userObj);
      commit("SET_ADDONS", addons);
      commit("SET_IMGREF", imgRef)
    },
    logout: ({ commit }) => {
      commit("RESET", "");
    },
    setLanguage: ({ commit }, locale) => {
      commit("SET_APP_LOCALE", locale);
    },

    async fetchLogo({ commit, state }) {
      // Get resellers ID from user state. 
      const resellerId = state.user?.reseller_id;
      // If no reseller connected to current user - set Securdia logo.
      if (!resellerId) {
        commit("SET_IMGREF", require("@/assets/logos/logo_menu.png"));
        return;
      }
      // If connected to reseller. Call API to fetch logo. Only resellers with whitelabel: true will respond with logo.
      try {
        const response = await axios.get(`${process.env.VUE_APP_SERVER_URL}/reseller/whitelabel/${resellerId}`);
        const logoUrl = response.data.logo || require("@/assets/logos/logo_menu.png"); 
        commit("SET_IMGREF", logoUrl);
      } catch (error) {
        console.error("Error fetching logo:", error);
        commit("SET_IMGREF", require("@/assets/logos/logo_menu.png")); // If an error occur, make sure standard logo is visible.
      }
    },
  },
  modules: {},
});
