<template>
  <modal name="modal-change-password" class="modal-inner modal-order-alarm" transition="pop-out" :width="modalWidth" :focus-trap="true" :min-height="600" height="auto" :scrollable="true">
    <span class="close-button" @click="hide('modal-change-password')"><BaseIcon icon="times-circle" class="text-white"/></span>
    <div class="modal-header">
      {{ $t("change_password_modal.change_password") }}
    </div>
    <ValidationObserver ref="formChangePassword">
      <div class="modal-body">
        <form class="flex flex-col">
          <div class="mb-8 pt-3 rounded">
            <label class="block text-gray-700 text-sm font-bold mb-2">{{ $t("change_password_modal.old_password") }}<b class="text-red-500 ml-1">*</b></label>
            <ValidationProvider name="Gammalt lösenord" rules="required" v-slot="{ classes, errors }">
              <div class="input-validate" :class="classes">
                <input type="password" v-model="oldPassword" class="input-username bg-gray-100 rounded w-full text-gray-900 focus:outline-none border-b-4 border-gray-300 transition duration-500 px-3 pb-3" />
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="mb-8 pt-3 rounded">
            <label class="block text-gray-700 text-sm font-bold mb-2">{{ $t("change_password_modal.new_password") }}<b class="text-red-500 ml-1">*</b></label>
            <ValidationProvider name="Nytt lösenord" rules="required" v-slot="{ classes, errors }" vid="password">
              <div class="input-validate" :class="classes">
                <input type="password" v-model="password" class="input-username bg-gray-100 rounded w-full text-gray-900 focus:outline-none border-b-4 border-gray-300 transition duration-500 px-3 pb-3" />
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="mb-8 pt-3 rounded">
            <label class="block text-gray-700 text-sm font-bold mb-2">{{ $t("change_password_modal.confirm_password") }}<b class="text-red-500 ml-1">*</b></label>
            <ValidationProvider name="Bekräfta lösenord" rules="required|confirmed:password" v-slot="{ classes, errors }">
              <div class="input-validate" :class="classes">
                <input type="password" v-model="confirmPassword" class="input-username bg-gray-100 rounded w-full text-gray-900 focus:outline-none border-b-4 border-gray-300 transition duration-500 px-3 pb-3" />
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <div class="footer-container clearfix">
          <button class="btn-red-outline px-3 py-2 float-left" @click="hide('modal-change-password')">{{ $t("user_actions.cancel") }}<BaseIcon icon="times-circle" class="ml-1" /></button>
          <button class="btn-green px-3 py-2 float-right" @click="changePassword()">{{ $t("change_password_modal.change_password") }}<BaseIcon icon="key" class="ml-1" /></button>
        </div>
      </div>
    </ValidationObserver>
  </modal>
</template>

<script>
const MODAL_WIDTH = 500;

export default {
  name: "ChangePasswordModal",
  data() {
    return {
      oldPassword: "",
      password: "",
      confirmPassword: "",
    };
  },
  methods: {
    async changePassword() {
      this.$refs.formChangePassword.validate().then(async (success) => {
        if (!success) {
          return;
        }

        try {
          let data = {
            old_password: this.oldPassword,
            password: this.password,
          };

          let response = await this.axios.post(`${process.env.VUE_APP_SERVER_URL}/auth/change_password`, data);

          if (response.data.error) this.handleError(null, response.data.message);

          if (response.data.success) {
            this.handleSuccess("Lösenordsändring lyckades");
            this.hide("modal-change-password");
          }
        } catch (error) {
          this.handleError(error);
        }
      });
    },

    show(id) {
      this.$modal.show(id);
    },

    hide(id) {
      this.oldPassword = "";
      this.password = "";
      this.confirmPassword = "";
      this.$refs.formChangePassword.reset();
      this.$modal.hide(id);
    },
  },

  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth : MODAL_WIDTH;
  },
};
</script>
