<template>
  <header class="flex justify-between items-center py-1 px-2 lg:px-6 bg-white border-b border-secondary-400">
    <div class="flex items-center">
      <button @click="toggleSidebar()" class="text-gray-500 focus:outline-none mr-5 lg:hidden">
        <svg class="h-6 w-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4 6H20M4 12H20M4 18H11" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
      </button>
      <div class="hidden lg:block text-sm">{{ pageTitle }}</div>
    </div>

    <div class="lg:hidden text-center">
      <router-link to="/"><img class="w-20" :src="require('@/assets/logos/logo_horizontal.png')"/></router-link>
    </div>

    <div class="flex items-center">
      <div class="mr-3">
        <LocaleSwitcher v-on:change="$emit('localeChange', $event)" />
      </div>
      <div class="relative inline-block" @mouseover="toggleDropdown(true)" @mouseleave="toggleDropdown(false)">
        <div class="focus:outline-none text-sm cursor-pointer">
          <BaseIcon class="text-primary-500 mr-2" icon="user" />
          <span class="mr-2"
            ><b class="text-primary-500">{{ username }}</b></span
          >
          <BaseIcon icon="chevron-down" />
        </div>
        <transition enter-active-class="transition ease-out duration-100" enter-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
          <div v-show="show_dropdown" class="absolute mt-2 lg:mt-0 right-0 w-48 bg-white rounded-md overflow-hidden shadow-xl z-40" ref="dropdown">
            <div class="block px-2 py-2 text-xxs text-gray-700 border-b border-secondary-400">
              <span class="text-gray-500 mr-1">{{ fullname }},</span>
              <span class="italic text-secondary-500">{{ role }}</span>
            </div>
            <div @click="openChangePassword()" class="block cursor-pointer px-4 py-2 text-xs text-gray-700 hover:bg-primary-600 hover:text-white">
              <BaseIcon icon="key" />
              <span class="mx-3">{{ $t("user_menu.change_password") }}</span>
            </div>
            <div @click="openVerifiedBrowser()" class="block cursor-pointer px-4 py-2 text-xs text-gray-700 hover:bg-primary-600 hover:text-white">
              <BaseIcon icon="desktop" />
              <span class="mx-3">{{ $t("user_menu.verified_browsers") }}</span>
            </div>
            <div @click="logout()" class="block cursor-pointer px-4 py-2 text-xs text-gray-700 hover:bg-primary-600 hover:text-white">
              <BaseIcon icon="sign-out-alt" />
              <span class="mx-3">{{ $t("user_menu.log_out") }}</span>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </header>
</template>

<script>
import LocaleSwitcher from "@/components/locale_switcher";

export default {
  name: "HeaderMenu",
  components: {
    LocaleSwitcher,
  },
  data() {
    return {
      user: this.$store.state.user,
      show_dropdown: false,
    };
  },
  methods: {
    toggleDropdown(val) {
      this.show_dropdown = val;
    },

    toggleSidebar() {
      this.$store.commit("SET_SIDEBAR_OPEN", !this.sidebarOpen);
    },

    openChangePassword() {
      this.show_dropdown = false;
      this.$modal.show("modal-change-password");
    },

    openVerifiedBrowser() {
      this.show_dropdown = false;
      this.$modal.show("modal-verified-browsers");
    },

    logout() {
      this.$store.dispatch("logout");
      let locale = localStorage.getItem("securcloud_locale") || "sv";
      this.$store.dispatch("setLanguage", locale);
      this.$router.push("/");
    },
  },
  computed: {
    username() {
      return this.$store.state.user.username;
    },
    fullname() {
      let firstname = this.$store.state.user.firstname || "";
      let lastname = this.$store.state.user.lastname || "";
      return firstname + " " + lastname;
    },
    role() {
      let role = this.$store.state.user.role;
      if (role == "superuser") return this.$t("user_role.customer");
      else if (role == "user") return this.$t("user_role.department");
      else if (role == "alarm_center") return this.$t("user_role.alarm_center");
      else if (role == "tech") return this.$t("user_role.tech");
      else if (role == "reseller") return this.$t("user_role.reseller");
      else if (role == "seller") return this.$t("user_role.seller");
      else if (role == "admin") return this.$t("user_role.admin");
      else return "";
    },
    pageTitle() {
      return this.$store.state.pageTitle;
    },
    sidebarOpen() {
      return this.$store.getters.getSidebarOpen;
    },
  },
  mounted() {},
};
</script>
