<template>
  <div v-bind:class="{ 'translate-x-0 ease-out': sidebarOpen, '-translate-x-full ease-in': !sidebarOpen }" class="menu flex flex-col justify-between fixed w-56 z-30 inset-y-0 left-0 transition duration-300 transform bg-secondary-900 overflow-y-auto lg:translate-x-0 lg:static lg:inset-0">
    <div>
      <div class="flex items-center justify-center mt-4">
        <div class="flex items-center mb-6">
          <router-link to="/">
            <img :src="logoUrl" alt="Company Logo" class="w-40" />
          </router-link>
        </div>
      </div>

      <div v-if="user.role != 'admin'" class="mx-6 border-b border-gray-900"></div>

      <!-- admin -->
      <nav class="mt-5" v-if="user.role == 'admin'">
        <transition-group name="menu-dropdown" tag="div">
          <div key="securcloud" class="flex items-center py-2 px-6 text-gray-100 text-sm cursor-pointer font-sans bg-addon-menu" @click="menu_securcloud = !menu_securcloud">
            <BaseIcon class="fa-fw" icon="location-arrow" />
            <span class="mx-3">{{ $t("sidenav_secure_cloud") }}</span>
            <BaseIcon icon="angle-down" v-if="!menu_securcloud" />
            <BaseIcon icon="angle-up" v-else />
          </div>
          <div key="securcloud_menu" v-if="menu_securcloud" class="rounded-sm">
            <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-xs font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'customers_admin', 'hover:bg-secondary-800': $route.name != 'customers_admin' }" to="/admin/kunder">
              <span class="ml-7">{{ $t("secure_cloud_menu.customer") }}</span>
            </router-link>
            <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-xs font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'alarm_central', 'hover:bg-secondary-800': $route.name != 'alarm_central' }" to="/admin/larmcentraler">
              <span class="ml-7">{{ $t("secure_cloud_menu.alarm_center") }}</span>
            </router-link>
            <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-xs font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'resellers_admin', 'hover:bg-secondary-800': $route.name != 'resellers_admin' }" to="/admin/reseller">
              <span class="ml-7">{{ $t("secure_cloud_menu.reseller") }}</span>
            </router-link>
            <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-xs font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'alarm_center_email_log', 'hover:bg-secondary-800': $route.name != 'alarm_center_email_log' }" to="/alarm-center/email-log/admin">
              <span class="ml-7">{{ $t("secure_cloud_menu.alarm_list") }}</span>
            </router-link>
            <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-xs font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'alarm_admin', 'hover:bg-secondary-800': $route.name != 'alarm_admin' }" to="/admin/larm">
              <span class="ml-7">{{ $t("secure_cloud_menu.delivery_document") }}</span>
            </router-link>
            <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-xs font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'order_document', 'hover:bg-secondary-800': $route.name != 'order_document' }" to="/admin/order-document">
              <span class="ml-7">{{ $t("secure_cloud_menu.stock") }}</span>
            </router-link>
          </div>

          <div key="organisation" class="flex items-center py-2 px-6 text-gray-100 text-sm cursor-pointer font-sans bg-addon-menu" @click="menu_organisation = !menu_organisation">
            <BaseIcon class="fa-fw" icon="building" />
            <span class="mx-3">{{ $t("organisation.organisation") }}</span>
            <BaseIcon icon="angle-down" v-if="!menu_organisation" />
            <BaseIcon icon="angle-up" v-else />
          </div>
          <div key="organisation_menu" v-if="menu_organisation" class="rounded-sm">
            <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-xs font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'users', 'hover:bg-secondary-800': $route.name != 'users' }" to="/users">
              <span class="ml-7">{{ $t("organisation.user") }}</span>
            </router-link>
            <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-xs font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'staffs', 'hover:bg-secondary-800': $route.name != 'staffs' }" to="/admin/staff">
              <span class="ml-7">{{ $t("organisation.personal") }}</span>
            </router-link>
            <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-xs font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'companyInfo', 'hover:bg-secondary-800': $route.name != 'companyInfo' }" to="/company-info">
              <span class="ml-7">{{ $t("organisation.info") }}</span>
            </router-link>
          </div>

          <div key="profile" class="flex items-center py-2 px-6 text-gray-100 text-sm cursor-pointer font-sans bg-addon-menu" @click="menu_profile = !menu_profile">
            <BaseIcon class="fa-fw" icon="user" />
            <span class="mx-3">{{ $t("profile.profile") }}</span>
            <BaseIcon icon="angle-down" v-if="!menu_profile" />
            <BaseIcon icon="angle-up" v-else />
          </div>
          <div key="profile_menu" v-if="menu_profile" class="rounded-sm">
            <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-xs font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'agreements', 'hover:bg-secondary-800': $route.name != 'agreements' }" to="/admin/avtal">
              <span class="ml-7">{{ $t("profile.agreement") }}</span>
            </router-link>
            <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-xs font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'sales', 'hover:bg-secondary-800': $route.name != 'sales' }" to="/sales/company/-">
              <span class="ml-7">{{ $t("profile.sale") }}</span>
            </router-link>
            <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-xs font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'admin_order_alarm_log', 'hover:bg-secondary-800': $route.name != 'admin_order_alarm_log' }" to="/admin/order-alarm-log">
              <span class="ml-7">{{ $t("profile.orders") }}</span>
            </router-link>
          </div>

          <div key="integration" class="flex items-center py-2 px-6 text-gray-100 text-sm cursor-pointer font-sans bg-addon-menu" @click="menu_integration = !menu_integration">
            <BaseIcon class="fa-fw" icon="cube" />
            <span class="mx-3">{{ $t("integration.integration") }}</span>
            <BaseIcon icon="angle-down" v-if="!menu_integration" />
            <BaseIcon icon="angle-up" v-else />
          </div>
          <div key="integration_menu" v-if="menu_integration" class="rounded-sm">
            <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-xs font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'addons', 'hover:bg-secondary-800': $route.name != 'addons' }" to="/admin/addons">
              <span class="ml-7">{{ $t("integration.additional_services") }}</span>
            </router-link>
            <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-xs font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'errorLogs', 'hover:bg-secondary-800': $route.name != 'errorLogs' }" to="/admin/error-logs">
              <span class="ml-7">{{ $t("integration.alarm_error_logs") }}</span>
            </router-link>
          </div>
        </transition-group>
      </nav>

      <!-- customer, unit -->
      <nav class="mt-10" v-if="user.role == 'superuser' || user.role == 'user'">
        <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-sm cursor-pointer font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'dashboard', 'hover:bg-secondary-800': $route.name != 'dashboard' }" :to="'/startsida/' + getId()">
          <BaseIcon class="fa-fw" icon="tachometer-alt" />
          <span class="mx-3">{{ $t("sidenav_reseller.dashboard") }}</span>
        </router-link>
        <router-link v-if="user.role == 'superuser'" class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-sm cursor-pointer font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'customer_agreements', 'hover:bg-secondary-800': $route.name != 'customer_agreements' }" :to="'/mina-avtal/customer/' + getId()">
          <BaseIcon class="fa-fw" icon="file-alt" />
          <span class="mx-3">{{ $t("sidenav_reseller.my_agreements") }}</span>
        </router-link>
        <router-link v-if="user.role == 'user'" class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-sm cursor-pointer font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'customer_agreements', 'hover:bg-secondary-800': $route.name != 'customer_agreements' }" :to="'/mina-avtal/unit/' + getId()">
          <BaseIcon class="fa-fw" icon="file-alt" />
          <span class="mx-3">{{ $t("sidenav_reseller.my_agreements") }}</span>
        </router-link>
        <router-link v-if="user.role == 'superuser' && user.customers_id.length > 1" class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-sm font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'customers', 'hover:bg-secondary-800': $route.name != 'customers' }" :to="'/kunder/customer/' + getId()">
          <BaseIcon class="fa-fw" icon="users" />
          <span class="mx-3">{{ $t("sidenav_reseller.activities") }}</span>
        </router-link>
        <router-link v-else-if="user.role == 'superuser' && user.customers_id.length == 1" class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-sm cursor-pointer font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'units', 'hover:bg-secondary-800': $route.name != 'units' }" :to="'/verksamheter/customer/' + user.customers_id[0] + '/-'">
          <BaseIcon class="fa-fw" icon="users" />
          <span class="mx-3">{{ $t("sidenav_reseller.activities") }}</span>
        </router-link>
        <router-link v-else class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-sm cursor-pointer font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'units', 'hover:bg-secondary-800': $route.name != 'units' }" :to="'/verksamheter/customer/' + getId() + '/-'">
          <BaseIcon class="fa-fw" icon="users" />
          <span class="mx-3">{{ $t("sidenav_reseller.activities") }}</span>
        </router-link>
        <router-link v-if="user.role == 'superuser'" class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-sm font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'users', 'hover:bg-secondary-800': $route.name != 'users' }" to="/users">
          <BaseIcon class="fa-fw" icon="user" />
          <span class="mx-3">{{ $t("sidenav_reseller.user") }}</span>
        </router-link>

        <div v-if="user.role == 'superuser' || user.role == 'user'" class="flex items-center py-2 px-6 text-gray-100 text-sm cursor-pointer font-sans" v-bind:class="addons.length == 0 && $route.name == 'addonBuy' ? 'bg-primary-700 bg-opacity-25' : 'bg-addon-menu '" @click="addonNavigation()">
          <BaseIcon class="fa-fw" icon="plus-circle" />
          <span class="mx-3">{{ $t("sidenav_reseller.additional_services") }}</span>
          <BaseIcon icon="angle-down" v-if="!addon_menu_active && addons.length > 0" />
          <BaseIcon icon="angle-up" v-if="addon_menu_active && addons.length > 0" />
        </div>
        <transition enter-active-class="transition ease-out duration-100" enter-class="transform -translate-y-2 opacity-0" enter-to-class="transform translate-y-0 opacity-100" leave-active-class="transition ease-in duration-75" leave-class="transform translate-y-0 opacity-100" leave-to-class="transform -translate-y-2 opacity-0">
          <div v-if="addon_menu_active" class="rounded-sm bg-addon-menu">
            <router-link v-if="gps_addon" class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-xs font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'gpsCustomer', 'hover:bg-secondary-800': $route.name != 'gpsCustomer' }" :to="'/gps-kund/' + getId()">
              <BaseIcon class="fa-fw" icon="map-marker-alt" />
              <span class="ml-3">{{ $t("sidenav_reseller.secur_track") }}</span>
            </router-link>
            <router-link v-if="log_addon" class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-xs font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'rounding_surveillance', 'hover:bg-secondary-800': $route.name != 'rounding_surveillance' }" :to="'/ronderingsbevakning/customer/' + getId()">
              <BaseIcon class="fa-fw" icon="history" />
              <span class="ml-3">{{ $t("sidenav_reseller.secur_tool") }}</span>
            </router-link>
            <router-link v-if="alarm_reminder" class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-xs font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'alarm_reminder_customer', 'hover:bg-secondary-800': $route.name != 'alarm_reminder_customer' }" :to="'/alarm-reminder/customer/' + getId()">
              <BaseIcon class="fa-fw" icon="bell" />
              <span class="ml-3">{{ $t("sidenav_reseller.alarm_reminder") }}</span>
            </router-link>
            <router-link v-if="trip_report" class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-xs font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'trip_report_customer', 'hover:bg-secondary-800': $route.name != 'trip_report_customer' }" :to="'/trip-report/customer/-'">
              <BaseIcon class="fa-fw" icon="bell" />
              <span class="ml-3">{{ $t("sidenav_reseller.trip_report") }}</span>
            </router-link>
            <router-link v-if="policy_addon" class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-xs font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'policy_routines', 'hover:bg-secondary-800': $route.name != 'policy_routines' }" to="/policy-routine">
              <BaseIcon class="fa-fw" icon="handshake" />
              <span class="ml-3">{{ $t("sidenav_reseller.policy") }}</span>
            </router-link>
            <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-xs font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'addonBuy', 'hover:bg-secondary-800': $route.name != 'addonBuy' }" to="/addon-activate">
              <BaseIcon class="fa-fw" icon="cube" />
              <span class="ml-3">{{ $t("sidenav_reseller.other_additional_services") }}</span>
            </router-link>
          </div>
        </transition>
      </nav>

      <!-- technician -->
      <nav class="mt-10" v-if="user.role == 'tech'">
        <transition-group name="menu-dropdown" tag="div">
          <div key="securcloud" class="flex items-center py-2 px-6 text-gray-100 text-sm cursor-pointer font-sans bg-addon-menu" @click="menu_securcloud = !menu_securcloud">
            <BaseIcon class="fa-fw" icon="location-arrow" />
            <span class="mx-3">{{ $t("sidenav_secure_cloud") }}</span>
            <BaseIcon icon="angle-down" v-if="!menu_securcloud" />
            <BaseIcon icon="angle-up" v-else />
          </div>
          <div key="securcloud_menu" v-if="menu_securcloud" class="rounded-sm">
            <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-xs font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'config_alarm', 'hover:bg-secondary-800': $route.name != 'config_alarm' }" to="/config/alarm">
              <span class="ml-7">{{ $t("technician_menu.configure_alarms") }}</span>
            </router-link>
            <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-xs font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'errorLogs', 'hover:bg-secondary-800': $route.name != 'errorLogs' }" to="/admin/error-logs">
              <span class="ml-7">{{ $t("technician_menu.all_Errors") }}</span>
            </router-link>
            <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-xs font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'search_alarm', 'hover:bg-secondary-800': $route.name != 'search_alarm' }" to="/alarm-report/search">
              <span class="ml-7">{{ $t("technician_menu.alarm_reports") }}</span>
            </router-link>
          </div>

          <div key="profile" class="flex items-center py-2 px-6 text-gray-100 text-sm cursor-pointer font-sans bg-addon-menu" @click="menu_profile = !menu_profile">
            <BaseIcon class="fa-fw" icon="user" />
            <span class="mx-3">{{ $t("technician_menu.profile") }}</span>
            <BaseIcon icon="angle-down" v-if="!menu_profile" />
            <BaseIcon icon="angle-up" v-else />
          </div>
          <div key="profile_menu" v-if="menu_profile" class="rounded-sm">
            <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-xs font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'customer_agreements', 'hover:bg-secondary-800': $route.name != 'customer_agreements' }" :to="'/mina-avtal/tech/' + getId()">
              <span class="ml-7">{{ $t("technician_menu.agreement") }}</span>
            </router-link>
            <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-xs font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'staff_profile', 'hover:bg-secondary-800': $route.name != 'staff_profile' }" :to="'/profile/update/' + getId()">
              <span class="ml-7">{{ $t("technician_menu.settings") }}</span>
            </router-link>
          </div>

          <div key="integration" class="flex items-center py-2 px-6 text-gray-100 text-sm cursor-pointer font-sans bg-addon-menu" @click="menu_integration = !menu_integration">
            <BaseIcon class="fa-fw" icon="cube" />
            <span class="mx-3">{{ $t("technician_menu.integration") }}</span>
            <BaseIcon icon="angle-down" v-if="!menu_integration" />
            <BaseIcon icon="angle-up" v-else />
          </div>
          <div key="integration_menu" v-if="menu_integration" class="rounded-sm">
            <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-xs font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'tech_securtool', 'hover:bg-secondary-800': $route.name != 'tech_securtool' }" to="/securtool/tech">
              <span class="ml-7">{{ $t("technician_menu.secur_tool") }}</span>
            </router-link>
            <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-xs font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'addonBuy', 'hover:bg-secondary-800': $route.name != 'addonBuy' }" to="/addon-activate">
              <span class="ml-7">{{ $t("technician_menu.additional_services") }}</span>
            </router-link>
          </div>
        </transition-group>
      </nav>

      <!-- alarm-center -->
      <nav class="mt-10" v-if="user.role == 'alarm_center'">
        <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-sm font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'alarm_center_dashboard', 'hover:bg-secondary-800': $route.name != 'alarm_center_dashboard' }" :to="'/alarm-center/dashboard/' + getId()">
          <BaseIcon class="fa-fw" icon="tachometer-alt" />
          <span class="mx-3">{{ $t("alarm_center_menu.dashboard") }}</span>
        </router-link>
        <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-sm font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'alarm_center_alarms', 'hover:bg-secondary-800': $route.name != 'alarm_center_alarms' }" :to="'/alarm-center/' + getId()">
          <BaseIcon class="fa-fw" icon="bell" />
          <span class="mx-3">{{ $t("alarm_center_menu.alarm_overview") }}</span>
        </router-link>
        <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-sm font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'alarm_center_track', 'hover:bg-secondary-800': $route.name != 'alarm_center_track' }" :to="'/alarm-center/securtrack/' + getId()">
          <BaseIcon class="fa-fw" icon="map-marker-alt" />
          <span class="mx-3">{{ $t("alarm_center_menu.secur_track") }}</span>
        </router-link>
        <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-sm font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'alarm_center_securtool', 'hover:bg-secondary-800': $route.name != 'alarm_center_securtool' }" :to="'/alarm-center/securtool/' + getId()">
          <BaseIcon class="fa-fw" icon="history" />
          <span class="mx-3">{{ $t("alarm_center_menu.secur_tool") }}</span>
        </router-link>
      </nav>

      <!-- reseller -->
      <nav class="mt-10" v-if="user.role == 'reseller'">
        <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-sm font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'reseller_dashboard', 'hover:bg-secondary-800': $route.name != 'reseller_dashboard' }" :to="'/reseller/' + getId()">
          <BaseIcon class="fa-fw" icon="tachometer-alt" />
          <span class="mx-3">{{ $t("reseller_menu.dashboard") }}</span>
        </router-link>
        <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-sm font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'customers', 'hover:bg-secondary-800': $route.name != 'customers' }" :to="'/kunder/reseller/' + getId()">
          <BaseIcon class="fa-fw" icon="users" />
          <span class="mx-3">{{ $t("secure_cloud_menu.customer") }}</span>
        </router-link>
        <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-sm cursor-pointer font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'customer_agreements', 'hover:bg-secondary-800': $route.name != 'customer_agreements' }" :to="'/mina-avtal/reseller/' + getId()">
          <BaseIcon class="fa-fw" icon="file-alt" />
          <span class="mx-3">{{ $t("reseller_menu.agreements") }}</span>
        </router-link>
        <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-sm font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'alarm_center_email_log', 'hover:bg-secondary-800': $route.name != 'alarm_center_email_log' }" :to="'/alarm-center/email-log/reseller/' + getId()">
          <BaseIcon class="fa-fw" icon="business-time" />
          <span class="mx-3">{{ $t("reseller_menu.alarm_background") }}</span>
        </router-link>
        <a class="flex items-center py-2 px-6 text-gray-100 text-sm cursor-pointer hover:bg-secondary-800 font-sans" @click="openOrderAlarmReseller()">
          <BaseIcon class="fa-fw" icon="shopping-bag" />
          <span class="mx-3">{{ $t("reseller_menu.orders") }}</span>
        </a>
        <router-link v-if="user.reseller_special_access.indexOf('users') > -1" class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-sm font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'users', 'hover:bg-secondary-800': $route.name != 'users' }" to="/users">
          <BaseIcon class="fa-fw" icon="user" />
          <span class="mx-3">{{ $t("reseller_menu.user") }}</span>
        </router-link>
        <router-link v-if="user.reseller_special_access.indexOf('config') > -1" class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-sm font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'config_alarm', 'hover:bg-secondary-800': $route.name != 'config_alarm' }" to="/config/alarm">
          <BaseIcon class="fa-fw" icon="cogs" />
          <span class="mx-3">{{ $t("reseller_menu.configure_alarms") }}</span>
        </router-link>
        <router-link v-if="user.reseller_special_access.indexOf('alarm_report') > -1" class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-sm font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'search_alarm', 'hover:bg-secondary-800': $route.name != 'search_alarm' }" to="/alarm-report/search">
          <BaseIcon class="fa-fw" icon="list" />
          <span class="mx-3">{{ $t("reseller_menu.live_alarm_report") }}</span>
        </router-link>
        <router-link v-if="user.reseller_special_access.indexOf('ac_securtrack') > -1 && user.reseller_alarm_center" class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-sm font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'alarm_center_track', 'hover:bg-secondary-800': $route.name != 'alarm_center_track' }" :to="'/alarm-center/securtrack/' + user.reseller_alarm_center">
          <BaseIcon class="fa-fw" icon="map-marker-alt" />
          <span class="mx-3">{{ $t("reseller_menu.secur_track") }}</span>
        </router-link>
        <router-link v-if="user.reseller_special_access.indexOf('ac_securtool') > -1 && user.reseller_alarm_center" class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-sm font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'alarm_center_securtool', 'hover:bg-secondary-800': $route.name != 'alarm_center_securtool' }" :to="'/alarm-center/securtool/' + user.reseller_alarm_center">
          <BaseIcon class="fa-fw" icon="history" />
          <span class="mx-3">{{ $t("reseller_menu.secur_tool") }}</span>
        </router-link>
        <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-sm font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'addonBuy', 'hover:bg-secondary-800': $route.name != 'addonBuy' }" to="/addon-activate">
          <BaseIcon class="fa-fw" icon="cube" />
          <span class="ml-3">{{ $t("reseller_menu.additional_services") }}</span>
        </router-link>
      </nav>

      <!-- seller -->
      <nav class="mt-5" v-if="user.role == 'seller'">
        <transition-group name="menu-dropdown" tag="div">
          <div key="securcloud" class="flex items-center py-2 px-6 text-gray-100 text-sm cursor-pointer font-sans bg-addon-menu" @click="menu_securcloud = !menu_securcloud">
            <BaseIcon class="fa-fw" icon="location-arrow" />
            <span class="mx-3">{{ $t("sidenav_secure_cloud") }}</span>
            <BaseIcon icon="angle-down" v-if="!menu_securcloud" />
            <BaseIcon icon="angle-up" v-else />
          </div>
          <div key="securcloud_menu" v-if="menu_securcloud" class="rounded-sm">
            <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-xs font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'customers_admin', 'hover:bg-secondary-800': $route.name != 'customers_admin' }" to="/admin/kunder">
              <span class="ml-7">{{ $t("secure_cloud_menu.customer") }}</span>
            </router-link>
            <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-xs font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'alarm_center_email_log', 'hover:bg-secondary-800': $route.name != 'alarm_center_email_log' }" to="/alarm-center/email-log/admin">
              <span class="ml-7">{{ $t("secure_cloud_menu.alarm_list") }}</span>
            </router-link>
            <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-xs font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'alarm_admin', 'hover:bg-secondary-800': $route.name != 'alarm_admin' }" to="/admin/larm">
              <span class="ml-7">{{ $t("secure_cloud_menu.delivery_document") }}</span>
            </router-link>
            <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-xs font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'order_document', 'hover:bg-secondary-800': $route.name != 'order_document' }" to="/admin/order-document">
              <span class="ml-7">{{ $t("secure_cloud_menu.stock") }}</span>
            </router-link>
            <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-xs font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'search_alarm', 'hover:bg-secondary-800': $route.name != 'search_alarm' }" to="/alarm-report/search">
              <span class="ml-7">{{ $t("technician_menu.alarm_reports") }}</span>
            </router-link>
            <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-xs font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'config_alarm', 'hover:bg-secondary-800': $route.name != 'config_alarm' }" to="/config/alarm">
              <span class="ml-7">{{ $t("reseller_menu.configure_alarms") }}</span>
            </router-link>
          </div>

          <div key="organisation" class="flex items-center py-2 px-6 text-gray-100 text-sm cursor-pointer font-sans bg-addon-menu" @click="menu_organisation = !menu_organisation">
            <BaseIcon class="fa-fw" icon="building" />
            <span class="mx-3">{{ $t("organisation.organisation") }}</span>
            <BaseIcon icon="angle-down" v-if="!menu_organisation" />
            <BaseIcon icon="angle-up" v-else />
          </div>
          <div key="organisation_menu" v-if="menu_organisation" class="rounded-sm">
            <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-xs font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'users', 'hover:bg-secondary-800': $route.name != 'users' }" to="/users">
              <span class="ml-7">{{ $t("organisation.user") }}</span>
            </router-link>
            <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-xs font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'staffs', 'hover:bg-secondary-800': $route.name != 'staffs' }" to="/admin/staff">
              <span class="ml-7">{{ $t("organisation.personal") }}</span>
            </router-link>
            <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-xs font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'companyInfo', 'hover:bg-secondary-800': $route.name != 'companyInfo' }" to="/company-info">
              <span class="ml-7">{{ $t("organisation.info") }}</span>
            </router-link>
          </div>

          <div key="profile" class="flex items-center py-2 px-6 text-gray-100 text-sm cursor-pointer font-sans bg-addon-menu" @click="menu_profile = !menu_profile">
            <BaseIcon class="fa-fw" icon="user" />
            <span class="mx-3">{{ $t("profile.profile") }}</span>
            <BaseIcon icon="angle-down" v-if="!menu_profile" />
            <BaseIcon icon="angle-up" v-else />
          </div>
          <div key="profile_menu" v-if="menu_profile" class="rounded-sm">
            <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-xs font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'customer_agreements', 'hover:bg-secondary-800': $route.name != 'customer_agreements' }" :to="'/mina-avtal/seller/' + getId()">
              <span class="ml-7">{{ $t("profile.agreement") }}</span>
            </router-link>
            <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-xs font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'sales', 'hover:bg-secondary-800': $route.name != 'sales' }" :to="'/sales/seller/' + getId()">
              <span class="ml-7">{{ $t("profile.sale") }}</span>
            </router-link>
            <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-xs font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'admin_order_alarm_log', 'hover:bg-secondary-800': $route.name != 'admin_order_alarm_log' }" :to="'/admin/order-alarm-log/' + getId()">
              <span class="ml-7">{{ $t("profile.orders") }}</span>
            </router-link>
            <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-xs font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'staff_profile', 'hover:bg-secondary-800': $route.name != 'staff_profile' }" :to="'/profile/update/' + getId()">
              <span class="ml-7">{{ $t("profile.settings") }}</span>
            </router-link>
          </div>

          <div key="integration" class="flex items-center py-2 px-6 text-gray-100 text-sm cursor-pointer font-sans bg-addon-menu" @click="menu_integration = !menu_integration">
            <BaseIcon class="fa-fw" icon="cube" />
            <span class="mx-3">{{ $t("integration.integration") }}</span>
            <BaseIcon icon="angle-down" v-if="!menu_integration" />
            <BaseIcon icon="angle-up" v-else />
          </div>
          <div key="integration_menu" v-if="menu_integration" class="rounded-sm">
            <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-xs font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'addons', 'hover:bg-secondary-800': $route.name != 'addons' }" to="/admin/addons">
              <span class="ml-7">{{ $t("integration.additional_services") }}</span>
            </router-link>
            <router-link class="flex items-center py-2 px-6 bg-opacity-25 text-gray-100 text-xs font-sans" v-bind:class="{ 'bg-primary-700': $route.name == 'errorLogs', 'hover:bg-secondary-800': $route.name != 'errorLogs' }" to="/admin/error-logs">
              <span class="ml-7">{{ $t("integration.alarm_error_logs") }}</span>
            </router-link>
          </div>
        </transition-group>
      </nav>
    </div>

    <!-- footer menu -->
    <nav class="mb-2">
      <div class="mx-6 border-b border-gray-900 my-2"></div>

      <a v-if="user.role != 'admin'" href="mailto:support@securdia.se" target="_blank" class="flex items-center py-2 px-6 text-gray-100 text-xs font-sans">
        <BaseIcon icon="question-circle" />
        <span class="ml-3">Support</span>
      </a>
      <a href="https://securdia.se" target="_blank" class="flex items-center py-2 px-6 text-gray-100 text-xs font-sans">
        <img class="w-3" :src="require('@/assets/logos/securdia-favicon.png')" />
        <span class="ml-3">Till securdia.se</span>
      </a>
    </nav>
  </div>
</template>

<script>
export default {
  name: "SidebarMenu",
  components: {},
  data() {
    return {
      addon_menu_active: true,
      menu_securcloud: true,
      menu_organisation: true,
      menu_profile: true,
      menu_integration: false,
      gps_addon: false,
      log_addon: false,
      alarm_reminder: false,
      trip_report: false,
      policy_addon: false,
    };
  },
  methods: {
    addonNavigation() {
      if (this.addons.length > 0) {
        this.addon_menu_active = !this.addon_menu_active;
      } else if (this.$route.name != "addonBuy") {
        this.$router.push({ name: "addonBuy" });
      }
    },

    openOrderAlarmReseller() {
      this.$modal.show("modal-order-alarm-reseller");
    },

    getId() {
      if (this.user.role == "superuser") return "-";
      else if (this.user.role == "user") return this.user.customers_id.length > 0 ? this.user.customers_id[0] : "-";
      else if (this.user.role == "alarm_center") return this.user.alarm_center_id;
      else if (this.user.role == "reseller") return this.user.reseller_id;
      else if (this.user.role == "seller") return this.user.seller_id;
      else if (this.user.role == "tech") return this.user.tech_id;
    },
    getLogoSrc() {
      return this.imgRef ? this.imgRef : require('@/assets/logos/logo_menu.png');
    }
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
    addons() {
      return this.$store.state.addons;
    },
    sidebarOpen() {
      return this.$store.getters.getSidebarOpen;
    },
    logoUrl() {
      return this.$store.getters.getImgRef;
    }
  },

  created() {
    //console.log("🚀 ~ file: sidebar_menu.vue:451 ~ created ~ role:", this.user.role);

    let unitsAddons = this.addons.units.length > 0 ? _.flatten(_.map(this.addons.units, "addons")) : [];
    this.$store.commit("SET_SIDEBAR_OPEN", false);
    // show addon link if found activate for atleast one customers/units
    if (this.user.role == "superuser") {
      let customerAddons = this.addons.customers.length > 0 ? _.flatten(_.map(this.addons.customers, "addons")) : [];
      let activeAddons = _.uniq(customerAddons);

      if (activeAddons.findIndex((e) => e == "gps_geofence") >= 0) this.gps_addon = true;
      if (activeAddons.findIndex((e) => e == "rounding_surveillance") >= 0) this.log_addon = true;
      if (activeAddons.findIndex((e) => e == "alarm_reminder") >= 0) this.alarm_reminder = true;
      if (activeAddons.findIndex((e) => e == "trip_report") >= 0) this.trip_report = true;
      if (activeAddons.findIndex((e) => e == "SAM") >= 0) this.policy_addon = true;
      if (activeAddons.length == 0) this.addon_menu_active = false;
    } else if (this.user.role == "user") {
      let activeAddons = _.uniq(unitsAddons);

      if (activeAddons.findIndex((e) => e == "gps_geofence") >= 0) this.gps_addon = true;
      if (activeAddons.findIndex((e) => e == "rounding_surveillance") >= 0) this.log_addon = true;
      if (activeAddons.findIndex((e) => e == "alarm_reminder") >= 0) this.alarm_reminder = true;
      if (activeAddons.findIndex((e) => e == "SAM") >= 0) this.policy_addon = true;
      if (activeAddons.length == 0) this.addon_menu_active = false;
    }
    if (!this.logoUrl || this.logoUrl === "/assets/logos/logo_menu.png") {
      this.$store.dispatch('fetchLogo');
    }
  },
};
</script>
