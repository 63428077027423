<template>
  <div class="login">
    <div class="fixed top-0 right-0 p-4">
      <LocaleSwitcher v-on:change="$emit('localeChange', $event)" />
    </div>
    <div class="min-h-screen bg-primary-300 bg-opacity-50 flex items-center justify-center">
      <div class="bg-white xl:w-1/3 p-8 rounded-lg shadow-xl">
        <div>
          <router-link to="/">
            <img class="logo w-40 mx-auto" :src="require('@/assets/logos/logo_horizontal.png')" />
          </router-link>
        </div>
        <div class="mt-8">
          <LoginForm></LoginForm>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from "@/components/login_form";
import LocaleSwitcher from "@/components/locale_switcher";
export default {
  name: "Login",
  title() {
    return `Login | SecurCloud`;
  },
  components: {
    LoginForm,
    LocaleSwitcher,
  },
  data() {
    return {
      user: this.$store.state.user,
    };
  },
};
</script>
