var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{staticClass:"modal-inner modal-order-alarm",attrs:{"name":"modal-change-password","transition":"pop-out","width":_vm.modalWidth,"focus-trap":true,"min-height":600,"height":"auto","scrollable":true}},[_c('span',{staticClass:"close-button",on:{"click":function($event){return _vm.hide('modal-change-password')}}},[_c('BaseIcon',{staticClass:"text-white",attrs:{"icon":"times-circle"}})],1),_c('div',{staticClass:"modal-header"},[_vm._v(" "+_vm._s(_vm.$t("change_password_modal.change_password"))+" ")]),_c('ValidationObserver',{ref:"formChangePassword"},[_c('div',{staticClass:"modal-body"},[_c('form',{staticClass:"flex flex-col"},[_c('div',{staticClass:"mb-8 pt-3 rounded"},[_c('label',{staticClass:"block text-gray-700 text-sm font-bold mb-2"},[_vm._v(_vm._s(_vm.$t("change_password_modal.old_password"))),_c('b',{staticClass:"text-red-500 ml-1"},[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"name":"Gammalt lösenord","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"input-validate",class:classes},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.oldPassword),expression:"oldPassword"}],staticClass:"input-username bg-gray-100 rounded w-full text-gray-900 focus:outline-none border-b-4 border-gray-300 transition duration-500 px-3 pb-3",attrs:{"type":"password"},domProps:{"value":(_vm.oldPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.oldPassword=$event.target.value}}}),_c('span',[_vm._v(_vm._s(errors[0]))])])]}}])})],1),_c('div',{staticClass:"mb-8 pt-3 rounded"},[_c('label',{staticClass:"block text-gray-700 text-sm font-bold mb-2"},[_vm._v(_vm._s(_vm.$t("change_password_modal.new_password"))),_c('b',{staticClass:"text-red-500 ml-1"},[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"name":"Nytt lösenord","rules":"required","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"input-validate",class:classes},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"input-username bg-gray-100 rounded w-full text-gray-900 focus:outline-none border-b-4 border-gray-300 transition duration-500 px-3 pb-3",attrs:{"type":"password"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('span',[_vm._v(_vm._s(errors[0]))])])]}}])})],1),_c('div',{staticClass:"mb-8 pt-3 rounded"},[_c('label',{staticClass:"block text-gray-700 text-sm font-bold mb-2"},[_vm._v(_vm._s(_vm.$t("change_password_modal.confirm_password"))),_c('b',{staticClass:"text-red-500 ml-1"},[_vm._v("*")])]),_c('ValidationProvider',{attrs:{"name":"Bekräfta lösenord","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"input-validate",class:classes},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmPassword),expression:"confirmPassword"}],staticClass:"input-username bg-gray-100 rounded w-full text-gray-900 focus:outline-none border-b-4 border-gray-300 transition duration-500 px-3 pb-3",attrs:{"type":"password"},domProps:{"value":(_vm.confirmPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.confirmPassword=$event.target.value}}}),_c('span',[_vm._v(_vm._s(errors[0]))])])]}}])})],1)])]),_c('div',{staticClass:"modal-footer"},[_c('div',{staticClass:"footer-container clearfix"},[_c('button',{staticClass:"btn-red-outline px-3 py-2 float-left",on:{"click":function($event){return _vm.hide('modal-change-password')}}},[_vm._v(_vm._s(_vm.$t("user_actions.cancel"))),_c('BaseIcon',{staticClass:"ml-1",attrs:{"icon":"times-circle"}})],1),_c('button',{staticClass:"btn-green px-3 py-2 float-right",on:{"click":function($event){return _vm.changePassword()}}},[_vm._v(_vm._s(_vm.$t("change_password_modal.change_password"))),_c('BaseIcon',{staticClass:"ml-1",attrs:{"icon":"key"}})],1)])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }