<template>
  <div class="grid">
    <label class="text-gray-700 text-sm font-bold mb-3 font-serif">{{ field_name }}</label>
    <input type="checkbox" v-bind="$attrs" :checked="value" @change="$emit('input', $event.target.checked)" class="switch disabled:opacity-50" />
  </div>
</template>

<script>
export default {
  props: {
    field_name: {
      type: String,
      default: "",
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  methods: {},
};
</script>
