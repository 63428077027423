<template>
  <modal name="modal-verified-browsers" class="modal-inner modal-verified-browsers" transition="pop-out" :width="modalWidth" :focus-trap="true" :min-height="600" height="auto" :scrollable="true">
    <span class="close-button" @click="hide('modal-verified-browsers')"><BaseIcon icon="times-circle" class="text-white"/></span>
    <div class="modal-header">{{ $t("verified_browsers.verified_browsers") }}</div>
    <div class="pt-3 pb-9 px-4">
      <div class="flex flex-col">
        <div v-if="verifiedDevices.length == 0" class="text-center text-secondary-500">{{ $t("verified_browsers.no_verified_browser") }}</div>
        <div class="clear-both mt-1 mb-2" v-for="(d, i) in verifiedDevices" :key="i">
          <div class="float-left">
            <BaseIcon icon="desktop" class="mr-2" v-bind:class="{ 'text-accent-500': d.visitorId == visitorId }" />
            <span class="capitalize" v-bind:class="{ 'text-accent-500': d.visitorId == visitorId }">{{ detectBrowser(d.browser, d.userAgent) }}, </span>
            <span class="capitalize mr-2" v-bind:class="{ 'text-accent-500': d.visitorId == visitorId }">{{ detectOS(d.userAgent, d.platform) }}</span>
            <span class="text-sm text-secondary-500">{{ $t("verified_browsers.last_verified_it") }}: {{ formatDate(d.timestamp) }}</span>
          </div>
          <div class="float-right">
            <button class="btn-red-outline" @click="removeVerifiedDevice(d.visitorId)">
              <BaseIcon icon="trash" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
const MODAL_WIDTH = 500;

export default {
  name: "VerifiedBrowsers",
  data() {
    return {
      user: this.$store.state.user,
      visitorId: "",
      verifiedDevices: [],
    };
  },
  methods: {
    getUserVerifiedDevices() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/user/verified-devices`)
        .then((response) => {
          this.verifiedDevices = response.data.verifiedDevices.sort(function(a, b) {
            return new Date(b.timestamp) - new Date(a.timestamp);
          });
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    async removeVerifiedDevice(visitorId) {
      try {
        // eslint-disable-next-line
        let response = await this.axios.delete(`${process.env.VUE_APP_SERVER_URL}/user/remove-device/${visitorId}`);
        this.getUserVerifiedDevices();
      } catch (error) {
        this.handleError(error);
      }
    },

    hide(id) {
      this.$modal.hide(id);
    },

    formatDate(date) {
      return this.moment(date).format("YYYY-MM-DD, HH:mm");
    },

    detectBrowser(browser, userAgent) {
      let detectedBrowser = browser;

      if (!browser && /Firefox/.test(userAgent)) detectedBrowser = "Firefox";
      else if (browser == "chrome" && /Edg/.test(userAgent)) detectedBrowser = "Microsoft Edge";

      return detectedBrowser;
    },

    detectOS(userAgent, platform) {
      let macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
        windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
        iosPlatforms = ["iPhone", "iPad", "iPod"],
        os = "Other";

      if (macosPlatforms.indexOf(platform) !== -1) {
        os = "Mac OS";
      } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = "iOS";
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = "Windows";
      } else if (/Android/.test(userAgent)) {
        os = "Android";
      } else if (/Linux/.test(platform)) {
        os = "Linux";
      }

      return os;
    },

    async getFingerprint() {
      const fp = await this.fpPromise;
      const result = await fp.get();
      // eslint-disable-next-line
      const { screenFrame, ...components } = result.components;
      this.visitorId = this.FingerprintJS.hashComponents(components);
    },
  },

  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth : MODAL_WIDTH;
    this.getUserVerifiedDevices();
    this.getFingerprint();
  },
};
</script>
