import { extend } from "vee-validate";
import { required, email, alpha_dash, confirmed, oneOf, between, numeric, max, min_value } from "vee-validate/dist/rules";
import { localize } from "vee-validate";
import sv from "vee-validate/dist/locale/sv.json";
import pl from "vee-validate/dist/locale/pl.json";
import en from "vee-validate/dist/locale/en.json";
import $store from "@/store";

localize($store.getters.getAppLocale, en);

$store.watch(
  (state) => state.appLocale,
  (newLocale) => {
    if (newLocale === "sv") {
      localize(newLocale, sv);
    }

    if (newLocale === "pl") {
      localize(newLocale, pl);
    }

    if (newLocale === "en") {
      localize(newLocale, en);
    }
  },
  { immediate: true }
);

extend("required", required);
extend("email", email);
extend("alpha_dash", alpha_dash);
extend("confirmed", confirmed);
extend("oneOf", oneOf);
extend("between", between);
extend("numeric", numeric);
extend("max", max);
extend("min_value", min_value);
