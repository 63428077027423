import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import axios from "@/utils/axios_base";
import helpers from "@/utils/helpers";
import title_mixin from "@/utils/title_mixin";
// library
import NProgress from "vue-nprogress";
import Toasted from "vue-toasted";
import VModal from "vue-js-modal";
import InputMask from "vue-input-mask";
import VueGoodTablePlugin from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import "@/utils/input_validation";
import * as VueGoogleMaps from "vue2-google-maps";
import GmapCluster from "vue2-google-maps/dist/components/cluster";
import _ from "lodash"; // eslint-disable-line
import moment from "moment-timezone";
import "moment/locale/sv";
import "moment/locale/pl";

import VueGtag from "vue-gtag";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import VueKonva from "vue-konva";
import i18n from "./i18n";
import $store from "@/store";

// components
import base_input from "@/components/base/_base_input";
import base_textarea from "@/components/base/_base_textarea";
import base_select from "@/components/base/_base_select";
import base_switch from "@/components/base/_base_switch";
import base_icon from "@/components/base/_base_icon";
import loading_spinner from "@/components/base/loading_spinner";

// browser fingerprint
let fpPromise = FingerprintJS.load();
Vue.prototype.FingerprintJS = FingerprintJS;
Vue.prototype.fpPromise = fpPromise;

// nprogress
Vue.use(NProgress, {
  latencyThreshold: 50,
  router: false,
  http: false,
});

const nprogress = new NProgress();

axios.interceptors.request.use(
  async function(config) {
    nprogress.start();
    config.headers.B = await getFingerprint();
    return config;
  },
  function(error) {
    nprogress.done();
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function(response) {
    nprogress.done();
    return response;
  },
  function(error) {
    nprogress.done();
    return Promise.reject(error);
  }
);

// global axios
Vue.prototype.axios = axios;

// global moment
moment.locale($store.getters.getAppLocale);

$store.watch(
  (state) => state.appLocale,
  (newLocale) => {
    moment.locale(newLocale);
  },
  { immediate: true }
);
Vue.prototype.moment = moment;

// vue google map
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_API_KEY,
    libraries: "geometry,places,drawing",
    installComponents: true,
  },
});
Vue.component("GmapCluster", GmapCluster);

// plugins
Vue.use(VueGoodTablePlugin);
Vue.use(Toasted);
Vue.use(VModal, { dialog: true });
Vue.use(VueGtag, {
  config: { id: "G-FJ12VS5WYV" },
});
Vue.use(VueKonva);

// external components
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("input-mask", InputMask);

// internal components
Vue.component("BaseInput", base_input);
Vue.component("BaseTextarea", base_textarea);
Vue.component("BaseSelect", base_select);
Vue.component("BaseSwitch", base_switch);
Vue.component("BaseIcon", base_icon);
Vue.component("LoadingSpinner", loading_spinner);

// mixins
Vue.mixin({
  methods: helpers,
});
Vue.mixin(title_mixin);

Vue.config.productionTip = false;

new Vue({
  nprogress,
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

async function getFingerprint() {
  let visitorId = "";

  try {
    const fp = await fpPromise;
    const result = await fp.get();
    // eslint-disable-next-line
    const { screenFrame, ...components } = result.components;
    visitorId = FingerprintJS.hashComponents(components);
  } catch (error) {
    console.log(error);
  }

  return visitorId;
}
