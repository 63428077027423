<template>
  <div id="app">
    <NprogressContainer />
    <Layout />
    <v-dialog />
  </div>
</template>

<script>
import NprogressContainer from "vue-nprogress/src/NprogressContainer";
import Layout from "@/components/layouts/layout";

export default {
  components: {
    NprogressContainer,
    Layout,
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  methods: {},
  created() {
    if (this.user) this.getUser();
  },
  mounted() {
    if (!this.$store.state.appLocale) {
      let locale = localStorage.getItem("securcloud_locale") || "sv";
      this.$store.commit("SET_APP_LOCALE", locale);
    }
    if (!localStorage.getItem("securcloud_locale")) {
      localStorage.setItem("securcloud_locale", "sv");
      this.$store.commit("SET_APP_LOCALE", "sv");
    }
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Source+Serif+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@import "./src/styles/global.scss";
</style>
