<template>
  <div class="locale-switcher">
    <select v-model="selectedLocale" @change="changeLocale">
      <option :value="locale.code" v-for="locale in locales" :key="locale.code">
        {{ locale.name }}
      </option>
    </select>
  </div>
</template>

<script>
import { getSupportedLocales } from "@/i18n/supported-locales";

export default {
  data: () => ({
    locales: getSupportedLocales(),
    selectedLocale: "",
  }),
  methods: {
    changeLocale(e) {
      this.selectedLocale = e.target.value;
      this.$i18n.locale = this.selectedLocale;
      this.$store.commit("SET_APP_LOCALE", this.selectedLocale);

      localStorage.setItem("securcloud_locale", this.selectedLocale);
    },
  },
  mounted() {
    const savedLocale = localStorage.getItem("securcloud_locale") || "sv";
    this.selectedLocale = savedLocale;
    this.$i18n.locale = savedLocale;
  },
};
</script>
