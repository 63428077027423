<template>
  <FontAwesomeIcon v-bind="$attrs" :icon="icon" />
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library as fontAwesomeIconLibrary } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

fontAwesomeIconLibrary.add(fas);

export default {
  components: {
    FontAwesomeIcon,
  },
  inheritAttrs: false,
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
};
</script>
