export default {
  contact_info: {
    company_name: "Securdia AB",
    company_number: "559270-2087",
    company_address: "Ekbacksvägen 22\n168 69 Bromma",
    email: "support@securdia.se",
    phone: "+46 08-520 276 42",
    contact_person: "Calle Baumgarten",
    contact_email: "calle@securdia.se",
    contact_phone: "+46 72-971 95 71",
  },
};
