import axios from "axios";
import $store from "@/store";
import $router from "@/router";

axios.defaults.headers.common["X-Access-Token"] = $store.state.token;
axios.defaults.headers.common["Locale"] = $store.getters.getAppLocale;

$store.watch(
  (state) => state.appLocale,
  (newLocale) => {
    axios.defaults.headers.common["Locale"] = newLocale;
  },
  { immediate: true }
);

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) prom.reject(error);
    else prom.resolve(token);
  });
  failedQueue = [];
};

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;

    if (error.response.status === 403) {
      $store.dispatch("logout");
      $router.push("/").catch(() => {});

      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function(resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["X-Access-Token"] = token;
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise(function(resolve, reject) {
        let data = { refresh_token: $store.state.refreshToken, username: $store.state.user.username };

        axios
          .post(`${process.env.VUE_APP_SERVER_URL}/auth/refresh_token`, data)
          .then(({ data }) => {
            $store.commit("SET_TOKEN", data.accessToken);
            originalRequest.headers["X-Access-Token"] = data.accessToken;
            axios.defaults.headers.common["X-Access-Token"] = data.accessToken;

            processQueue(null, data.accessToken);
            resolve(axios(originalRequest));
          })
          .catch((err) => {
            processQueue(err, null);
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error);
  }
);

export default axios;
