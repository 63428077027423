<template>
  <ValidationProvider :name="field_name" :rules="rules" v-slot="{ classes, errors }">
    <div class="input-validate" :class="classes">
      <label class="text-gray-700 text-sm font-bold pb-2 font-serif">{{ field_name }}<b v-if="new RegExp('(required)').test(rules)" class="text-red-500 ml-1">*</b></label>
      <select v-bind="$attrs" @input="$emit('update', $event.target.value)" v-on="$listeners" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-4 border-gray-300 transition duration-500 px-3 pt-2 pb-2" v-bind:class="{ 'hover:border-gray-400': new RegExp('(required)').test(rules) }">
        <option value="">{{ $t("choose") }} {{ field_name }}</option>
        <template v-if="optionSelector == ''">
          <option :value="v" v-for="(v, i) in options" :key="i">{{ v }}</option>
        </template>
        <template v-else>
          <option :value="o[optionSelector]" v-for="(o, i) in options" :key="i">{{ o[nameSelector] }}</option>
        </template>
      </select>
      <span>{{ errors[0] }}</span>
    </div>
  </ValidationProvider>
</template>

<script>
export default {
  inheritAttrs: false,
  model: {
    event: "update",
  },
  props: {
    field_name: {
      type: String,
      default: "",
    },
    rules: {
      type: String,
      default: "",
    },
    options: {
      required: true,
    },
    nameSelector: {
      type: String,
      default: "",
    },
    optionSelector: {
      type: String,
      default: "",
    },
  },
};
</script>
