<template>
  <modal name="modal-order-alarm-reseller" class="modal-inner modal-order-alarm-reseller" transition="pop-out" :width="modalWidth" :focus-trap="true" :min-height="600" height="auto" :scrollable="true" :resizable="true" :adaptive="true" :reset="true" @before-close="resetForm">
    <span class="close-button" @click="hide('modal-order-alarm-reseller')"><BaseIcon icon="times-circle" class="text-white"/></span>
    <div class="modal-header">
      Beställ nytt alarm
      <span v-if="selectedScheme == 'cash'">- Kontant</span>
      <span v-if="selectedScheme == 'rental'">- Hyres</span>
      <span v-if="selectedScheme == 'kickback'">- Kickback</span>
    </div>
    <ValidationObserver ref="formOrderAlarmReseller">
      <div class="modal-body" v-if="reseller">
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/2 px-2">
            <BaseInput v-model="reseller.name" type="text" field_name="Namn" readonly disabled />
          </div>
          <div class="w-full md:w-1/2 px-2">
            <BaseInput v-model="reseller.email" type="text" field_name="Email" readonly disabled />
          </div>
          <div class="w-full md:w-1/2 px-2">
            <BaseInput v-model="reseller.phone" type="text" field_name="Telefon" readonly disabled />
          </div>
        </div>

        <div class="mx-2 border-b border-secondary-400 my-5"></div>
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/2 px-2">
            <BaseInput v-model="contact_name" type="text" field_name="Namn på beställare" rules="required" />
          </div>
          <div class="w-full md:w-1/2 px-2">
            <BaseInput v-model="contact_phone" type="text" field_name="Kontaktnummer" rules="required" />
          </div>
        </div>

        <div class="mx-2 border-b border-secondary-400 my-5"></div>
        <div class="flex flex-wrap">
          <div class="w-full px-2" v-bind:class="{ 'md:w-1/2': reseller.reseller_type != 'alarm_center' }">
            <BaseTextarea rows="5" v-model="message" field_name="Information om beställning" rules="required" />
          </div>
          <div v-if="reseller.reseller_type != 'alarm_center'" class="w-full md:w-1/2 px-2">
            <BaseSelect v-model="alarm_center" :options="alarmCentrals" nameSelector="name" optionSelector="name" field_name="Larmcentral " rules="required" />
          </div>
        </div>

        <div class="mx-2 border-b border-secondary-400 my-5"></div>
        <div class="flex flex-wrap">
          <div class="w-full md:w-1/2 px-2">
            <ValidationProvider name="Upplägg" rules="required" v-slot="{ classes, errors }">
              <div class="input-validate" :class="classes">
                <label class="text-gray-700 text-sm font-bold pb-2 font-serif">Upplägg<b class="text-red-500 ml-1">*</b></label>
                <select v-model="selectedScheme" @change="changeSelectedPrice()" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-4 border-gray-300 transition duration-500 px-3 pt-2 pb-2 hover:border-gray-400">
                  <option :value="o.id" v-for="o in pricelist" :key="o.id">
                    <span v-if="o.id == 'cash'">Kontant</span>
                    <span v-else-if="o.id == 'rental'">Hyres</span>
                    <span v-else-if="o.id == 'kickback'">Kickback</span>
                  </option>
                </select>
                <span>{{ errors[0] }}</span>
              </div>
            </ValidationProvider>
          </div>
        </div>
        <div class="flex flex-wrap mt-4">
          <div class="w-full py-6 px-6 bg-primary-50">
            <div class="w-full pb-3 mb-5 border-b-2 border-gray-200">
              <label class="inline-flex">
                <input v-model="securCloudActive" type="checkbox" class="form-checkbox h-4 w-4" @change="toggleSecurCloud()" />
                <span class="ml-2 text-xs font-sans font-semibold text-primary-500">SecurCloud</span>
              </label>
            </div>
            <div class="w-full flex flex-wrap mb-4">
              <div class="w-1/3 text-sm font-bold">Product</div>
              <div class="w-1/3 text-sm font-bold">
                <span v-if="selected_pricelist && selected_pricelist.id == 'kickback'">Kickback</span>
                <span v-else>Price</span>
              </div>
              <div class="w-1/3 text-sm font-bold">Quantity</div>
            </div>
            <template v-for="p in selected_pricelist.prices">
              <div v-if="p.price != 0" v-bind:key="p.id" class="w-full flex flex-wrap my-1 border-b border-gray-300">
                <div class="w-1/3 text-sm my-auto transition-all delay-100 ease-linear duration-200">
                  {{ p.name }}
                  <BaseIcon v-if="p.id == 'SecurFull' || p.id == 'SecurBas'" icon="shield-alt" class="ml-1 transition-all delay-75 duration-150 ease-linear" v-bind:class="securCloudActive ? 'opacity-100 text-primary-500 text-lg' : 'opacity-30'" />
                </div>
                <div class="w-1/3 my-auto">
                  <span class="text-sm">{{ p.price | currency }}</span>
                  <span class="pl-1 text-xs" v-if="p.price_type == 'month'"> / månad</span>
                </div>
                <div class="w-1/3 my-auto">
                  <ValidationProvider :name="p.name" rules="required" v-slot="{ classes, errors }">
                    <div class="input-validate" :class="classes">
                      <input type="number" min="0" v-model="p.quantity" @change="changeSecurCloud(p.id)" class="bg-gray-100 rounded w-full text-sm text-gray-900 focus:outline-none border-b-2 border-gray-300 transition duration-500 px-3 pt-1 pb-1" />
                      <span>{{ errors[0] }}</span>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="footer-container clearfix">
          <button class="btn-red-outline px-3 py-2 float-left" @click="hide('modal-order-alarm-reseller')">Avbryt<BaseIcon icon="times-circle" class="ml-1" /></button>
          <button class="btn-blue px-3 py-2 float-right" @click="orderNewAlarm()">
            Beställ
            <span v-if="selectedScheme == 'cash'">Kontant</span>
            <span v-if="selectedScheme == 'rental'">Hyres</span>
            <span v-if="selectedScheme == 'kickback'">Kickback</span>
            <BaseIcon icon="arrow-right" class="ml-1" />
          </button>
        </div>
      </div>
    </ValidationObserver>
  </modal>
</template>

<script>
const MODAL_WIDTH = 650;

export default {
  name: "OrderAlarmModalReseller",
  data() {
    return {
      user: this.$store.state.user,
      reseller_id: null,
      reseller: null,
      alarmCentrals: [],
      pricelist: [],
      contact_name: "",
      contact_phone: "",
      message: "",
      alarm_center: "",
      selectedScheme: "cash",
      selected_pricelist: null,
      securCloudActive: false,
    };
  },
  methods: {
    async getReseller() {
      try {
        let response = await this.axios.get(`${process.env.VUE_APP_SERVER_URL}/reseller/${this.reseller_id}`);
        this.reseller = response.data.data;
        this.contact_name = this.reseller.contact_person;
        this.contact_phone = this.reseller.contact_phone;

        for (let i = 0; i < this.reseller.pricelist.length; i++) {
          let o = this.reseller.pricelist[i];
          o.prices = o.prices.map((p) => ({
            ...p,
            quantity: 0,
          }));
        }
        this.pricelist = _.cloneDeep(this.reseller.pricelist);

        if (this.pricelist.length > 0) this.selectedScheme = this.pricelist[0].id;

        this.changeSelectedPrice();

        if (this.reseller.special_access.indexOf("order_sc_qty_default") > -1) this.securCloudActive = true;
      } catch (error) {
        this.handleError(error);
      }
    },

    getAlarmCentrals() {
      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/alarm-central`)
        .then((response) => {
          this.alarmCentrals = response.data.data;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },

    orderNewAlarm() {
      this.$refs.formOrderAlarmReseller.validate().then(async (success) => {
        if (!success) {
          this.handleError(null, "Några av obligatoriska fält är tomma");
          return;
        }

        let data = {
          contact_name: this.contact_name,
          contact_phone: this.contact_phone,
          message: this.message,
          alarm_center: this.alarm_center,
          pricelist: this.selected_pricelist,
        };

        try {
          // eslint-disable-next-line
          let response = await this.axios.post(`${process.env.VUE_APP_SERVER_URL}/reseller/order-alarm`, data);

          this.handleSuccess("Beställningslarm lyckades");
          this.hide("modal-order-alarm-reseller");
        } catch (error) {
          this.handleError(error);
        }
      });
    },

    changeSelectedPrice() {
      let s = _.find(this.pricelist, { id: this.selectedScheme });
      if (s) this.selected_pricelist = s;
    },

    changeSelectedScheme(id) {
      this.selectedScheme = id;
      this.changeSelectedPrice();
    },

    changeSecurCloud(id) {
      // only allow to change on desired select
      if (this.securCloudActive && id != "SecurBas" && id != "SecurFull" && id != "SecurdiaCare" && id != "Simkort" && id != "Larmcentral") {
        // update plan quantity based on plans & device
        this.calculatePlanQuantity();
      }
    },

    calculatePlanQuantity() {
      let plan1Index = _.findIndex(this.selected_pricelist.prices, { id: "SecurFull" });
      if (plan1Index > -1 && this.selected_pricelist.prices[plan1Index].price > 0) {
        let qty = 0;

        for (let i = 0; i < this.selected_pricelist.prices.length; i++) {
          let p = this.selected_pricelist.prices[i];
          let isPlan1Device = p.id != "SRT330" && p.id != "SRT334" && p.id != "TWIG-Beacon" && p.id != "TWIG-Beacon-EX" && p.id != "AnchorBeacon" && p.id != "SecurBas" && p.id != "SecurFull" && p.id != "SecurdiaCare" && p.id != "Simkort" && p.id != "Larmcentral";

          if (isPlan1Device) qty += parseInt(p.quantity);
        }

        this.selected_pricelist.prices[plan1Index].quantity = qty;
      }

      let plan2Index = _.findIndex(this.selected_pricelist.prices, { id: "SecurBas" });
      if (plan2Index > -1 && this.selected_pricelist.prices[plan2Index].price > 0) {
        let qty = 0;

        for (let i = 0; i < this.selected_pricelist.prices.length; i++) {
          let p = this.selected_pricelist.prices[i];
          let isPlan2Device = p.id == "SRT330" || p.id == "SRT334" || p.id == "TWIG-Beacon" || p.id == "TWIG-Beacon-EX" || p.id == "AnchorBeacon";

          if (isPlan2Device) qty += parseInt(p.quantity);
        }

        this.selected_pricelist.prices[plan2Index].quantity = qty;
      }
    },

    resetQuantity() {
      let plan1Index = _.findIndex(this.selected_pricelist.prices, { id: "SecurFull" });
      if (plan1Index > -1) this.selected_pricelist.prices[plan1Index].quantity = 0;

      let plan2Index = _.findIndex(this.selected_pricelist.prices, { id: "SecurBas" });
      if (plan2Index > -1) this.selected_pricelist.prices[plan2Index].quantity = 0;
    },

    toggleSecurCloud() {
      if (this.securCloudActive) this.calculatePlanQuantity();
      else this.resetQuantity();
    },

    show(id) {
      this.$modal.show(id);
    },

    hide(id) {
      this.$refs.formOrderAlarmReseller.reset();
      this.$modal.hide(id);
    },

    resetForm() {
      if (this.reseller) {
        this.pricelist = _.cloneDeep(this.reseller.pricelist);
        if (this.pricelist.length > 0) this.selectedScheme = this.pricelist[0].id;
        this.changeSelectedPrice();
      }
      this.message = "";
      this.$refs.formOrderAlarmReseller.reset();

      if (this.reseller && this.reseller.special_access.indexOf("order_sc_qty_default") > -1) this.securCloudActive = true;
      else this.securCloudActive = false;
    },
  },

  filters: {
    currency: function(value) {
      if (!value) return "";
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " kr";
    },
  },

  created() {
    this.modalWidth = window.innerWidth < MODAL_WIDTH ? window.innerWidth : MODAL_WIDTH;

    this.reseller_id = this.user.reseller_id;
    if (this.user.role == "reseller") {
      this.getReseller();
      this.getAlarmCentrals();
    }
  },
};
</script>
